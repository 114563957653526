<template>
  <div class="login">
    <!-- 登录盒子，页面居中 -->
    <div class="login_container">
      <!-- 左边图片 -->
      <div class="login_img">
        <img
          src="../../assets/img/login/1827.png"
          alt=""
        />
      </div>
      <!-- 右边登录框 -->
      <div
        class="login_box"
        v-if="isShow"
      >
        <!-- 标题，position布局 -->
        <div class="title">
          欢迎使用智慧农贸后台管理系统
        </div>
        <!-- 内容区域，表单形式 -->
        <div class="content">
          <!-- tabs切换，默认chanel为1 -->
          <el-tabs
            v-model="activeName"
            @tab-click="handleClick"
          >
            <!-- 验证码登录部分 -->
            <el-tab-pane
              class="123"
              label="手机快捷登录"
              name="first"
            >
              <!-- 登录表单区域 -->
              <el-form
                ref="loginFormRef"
                :model="loginUserForm"
                :rules="loginFormRules"
                label-width="0px"
                class="login_form"
              >
                <!-- 用户名 -->

                <el-form-item
                  prop="mobile"
                  class="mobile"
                >
                  <el-input
                    v-model="loginUserForm.mobile"
                    placeholder="请输入手机号"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    @input="changeMobile"
                  >
                    <i
                      slot="prefix"
                      style="display: flex;align-items: center;"
                    >
                      <img
                        src="../../assets/img/login/23.png"
                        alt
                      />

                    </i>
                  </el-input>
                </el-form-item>
                <!-- 验证码 -->

                <el-form-item
                  prop="varify"
                  class="varify"
                >
                  <el-input
                    v-model="loginUserForm.verifyCode"
                    placeholder="请输入验证码"
                    oninput="value=value.replace(/[^\d]/g,'')"
                  >
                    <i
                      slot="prefix"
                      style="display: flex;align-items: center;"
                    >
                      <img
                        src="../../assets/img/login/24.png"
                        alt
                      />

                    </i>
                  </el-input>
                  <!-- 发送验证码按钮 -->
                  <el-button
                    class="getVarify"
                    type="primary"
                    :disabled="isDisabled"
                    @click="sendCodeByLogin"
                  >{{ buttonText }}</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <!-- 密码登录部分 -->
            <el-tab-pane
              label="密码登录"
              name="second"
            >
              <!-- 登录表单区域 -->
              <el-form
                ref="loginFormRef2"
                :model="loginUserForm2"
                :rules="loginFormRules2"
                label-width="0px"
                class="login_form"
              >
                <!-- 用户名 -->

                <el-form-item
                  prop="mobile"
                  class="mobile"
                >
                  <el-input
                    v-model="loginUserForm2.mobile"
                    placeholder="请输入手机号"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    @input="changeMobile2"
                  >
                    <i
                      slot="prefix"
                      style="display: flex;align-items: center;"
                    >
                      <img
                        src="../../assets/img/login/1657.png"
                        alt
                      />

                    </i>
                  </el-input>
                </el-form-item>
                <!-- 密码 -->

                <el-form-item
                  prop="password"
                  class="mobile"
                >
                  <el-input
                    v-model="loginUserForm2.password"
                    placeholder="请输入密码"
                    show-password
                    @input="passwordInput"
                  >
                    <i
                      slot="prefix"
                      style="display: flex;align-items: center;"
                    >
                      <img
                        src="../../assets/img/login/2599.png"
                        alt
                      />

                    </i>
                  </el-input>
                  <i class="icon-biyan" />
                </el-form-item>
                <div class="aboutPassword">
                  <!-- 记住密码，默认勾选 -->
                  <el-checkbox
                    v-model="checked"
                    style="color:#a0a0a0;margin:0 0 20px 0"
                  >记住密码</el-checkbox>
                  <div
                    class="forget"
                    @click="forgetPassword"
                  >忘记密码？</div>
                </div>
              </el-form>
            </el-tab-pane>
            <!-- 按钮 -->
            <el-button
              class="btn"
              type="primary"
              round
              @click="login"
            >登录</el-button>
          </el-tabs>
        </div>
      </div>
      <!-- 忘记密码 -->
      <div
        class="login_box"
        v-if="isShowForget"
      >
        <!-- 标题，position布局 -->
        <div class="title">
          欢迎使用智慧农贸后台管理系统
        </div>
        <!-- 内容区域，表单形式 -->
        <div class="content">
          <!-- tabs切换，默认chanel为1 -->
          <el-tabs v-model="activeName2">
            <!-- 验证码登录部分 -->
            <el-tab-pane
              class="123"
              label="忘记密码"
              name="first"
            >
              <!-- 登录表单区域 -->
              <el-form
                ref="forgetFormRef"
                :model="resetPasswordForm"
                :rules="forgetFormRules"
                label-width="0px"
                class="login_form"
              >
                <!-- 用户名 -->
                <el-form-item
                  prop="mobile"
                  class="mobile"
                >
                  <el-input
                    v-model="resetPasswordForm.mobile"
                    placeholder="请输入手机号"
                    oninput="value=value.replace(/[^\d]/g,'')"
                  >
                    <i
                      slot="prefix"
                      style="display: flex;align-items: center;"
                    >
                      <img
                        src="../../assets/img/login/23.png"
                        alt
                      />
                    </i>
                  </el-input>
                </el-form-item>
                <!-- 验证码 -->

                <el-form-item
                  prop="varify"
                  class="varify"
                >
                  <el-input
                    v-model="resetPasswordForm.verifyCode"
                    placeholder="请输入验证码"
                    oninput="value=value.replace(/[^\d]/g,'')"
                  >
                    <i
                      slot="prefix"
                      style="display: flex;align-items: center;"
                    >
                      <img
                        src="../../assets/img/login/24.png"
                        alt
                      />
                    </i>
                  </el-input>
                  <!-- 发送验证码按钮 -->
                  <el-button
                    class="getVarify"
                    type="primary"
                    :disabled="isDisabled"
                    @click="sendCodeByReset"
                  >{{ buttonText }}</el-button>
                </el-form-item>
                <!-- 密码 -->

                <el-form-item
                  prop="password"
                  class="mobile"
                >
                  <el-input
                    v-model="resetPasswordForm.password"
                    placeholder="请输入密码"
                    show-password
                    @input="passwordInput2"
                  >
                    <i
                      slot="prefix"
                      style="display: flex;align-items: center;"
                    >
                      <img
                        src="../../assets/img/login/2599.png"
                        alt
                      />
                    </i>
                  </el-input>
                  <i class="icon-biyan" />
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <!-- 按钮 -->
            <div class="reset">
              <el-button
                class="btn2"
                round
                @click="goBack"
              >返回</el-button>
              <el-button
                class="btn2"
                type="primary"
                round
                @click="resetPassword"
              >修改密码</el-button>
            </div>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // tabs切换，默认在验证码登录
      activeName: 'first',
      activeName2: 'first',
      // 登录提交入参
      loginUserForm: {
        mobile: '',
        password: '',
        channel: 1, // 登录方式:1.验证码，2:密码登录
        verifyCode: ''
      },
      // 密码登录
      loginUserForm2: {
        mobile: '',
        password: '',
        channel: 2, // 登录方式:1.验证码，2:密码登录
        verifyCode: ''
      },
      // 验证规则
      loginFormRules: {
        // 账号必填，3-30位通过
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur' }
        ]

        // 验证码必填
        // varify: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      // 密码验证规则
      loginFormRules2: {
        // 账号必填，3-30位通过
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur' }
        ],
        // 密码必填，5-10位通过
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 30, message: '长度在 5 到 30 个字符', trigger: 'blur' }
        ]
        // 验证码必填
        // varify: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      // 忘记密码
      forgetFormRules: {
        // 账号必填，3-30位通过
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur' }
        ],
        // 密码必填，5-10位通过
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 30, message: '长度在 5 到 30 个字符', trigger: 'blur' }
        ]
        // 验证码必填
        // varify: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      // 记住密码
      checked: true,
      // 是否禁止点击发送验证码按钮
      isDisabled: false,
      flag: true,
      // 按钮字体，可变
      buttonText: '获取验证码',
      // 获取验证码的入参
      verifyQueryInfo: {
        bizType: undefined, // 31登录，32忘记密码
        mobile: ''
      },
      // 登录框是否要展示，默认展示
      isShow: true,
      // 忘记密码展示
      isShowForget: false,
      resetPasswordForm: {
        mobile: '',
        password: '',
        verifyCode: ''
      }
    }
  },
  mounted () {
    // 获取cookie的方法
    this.account()
  },
  created () { },
  methods: {
    // 切换Tab
    handleClick (tab, event) {
      // tab的index,0和1
      if (tab.index === '0') {
        this.loginUserForm.channel = 1
      } else {
        this.loginUserForm.channel = 2
      }
    },
    // 重置输入框
    resetLoginForm () {
      this.$refs.loginFormRef.resetFields()
    },
    // 点击登录按钮
    login () {
      // 判断当前在哪个tab
      if (this.loginUserForm.channel === 1) {
        // 验证码登录
        // 验证表单是否合理
        this.$refs.loginFormRef.validate(async (valid) => {
          if (!valid) return
          // 判断是否选择了记住密码
          // if (this.checked === true) {
          //   this.setCookie(
          //     this.loginUserForm.mobile,
          //     this.loginUserForm.password,
          //     7
          //   )
          // } else {
          //   this.clearCookie()
          // }
          // 验证通过，进行下一步操作
          const response = await this.$http.post('login', this.loginUserForm)
          // console.log(response)
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message)
          }
          this.$message.success('登录成功')
          // 用户名放到session
          window.sessionStorage.setItem('userName', response.data.data.userName)
          window.sessionStorage.setItem('mobile', response.data.data.mobile)
          // 清空验证码
          // 保留token
          // console.log(response.headers['admin-authorization'])
          window.sessionStorage.setItem(
            'token',
            response.headers['admin-authorization']
          )
          window.sessionStorage.setItem(
            'currentMarketId',
            response.data.data.marketList[0].id
          )
          // 路由跳转到home
          this.$router.push('/home')
        })
      } else {
        // 密码登录
        // 验证表单是否合理
        this.$refs.loginFormRef2.validate(async (valid) => {
          if (!valid) return
          // 判断是否选择了记住密码
          if (this.checked === true) {
            // 存入cookie，保存7天
            this.setCookie(
              this.loginUserForm2.mobile,
              this.loginUserForm2.password,
              7
            )
          } else {
            this.clearCookie()
          }
          // 验证通过，进行下一步操作
          const response = await this.$http.post('login', this.loginUserForm2)
          // console.log(response)
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message)
          }
          this.$message.success('登录成功')
          // 用户名放到session
          window.sessionStorage.setItem('userName', response.data.data.userName)
          window.sessionStorage.setItem('mobile', response.data.data.mobile)
          // 清空验证码
          // 保留token
          // console.log(response.headers['admin-authorization'])
          window.sessionStorage.setItem(
            'token',
            response.headers['admin-authorization']
          )
          window.sessionStorage.setItem(
            'currentMarketId',
            response.data.data.marketList[0].id
          )
          // 路由跳转到home
          this.$router.push('/home')
        })
      }
    },
    account () {
      if (document.cookie.length !== 0) {
        console.log(this.getCookie('mobile'))
        this.loginUserForm.mobile = this.getCookie('mobile')
        this.loginUserForm2.mobile = this.getCookie('mobile')
        this.loginUserForm2.password = this.getCookie('password')
      }
    },
    setCookie (mobile, password, exdate) {
      // 账号，密码 ，过期的天数
      // eslint-disable-next-line no-redeclare
      var exdate = new Date()
      console.log(mobile, password)
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdate) // 保存的天数
      document.cookie =
        'mobile=' + mobile + ';path=/;expires=' + exdate.toLocaleString()
      document.cookie =
        'password=' + password + ';path=/;expires=' + exdate.toLocaleString()
    },
    getCookie (name) {
      var arr = document.cookie.split(';')
      // ["_ga=GA1.1.1756734561.1561034020", " mobile=123" password=456"]
      for (var i = 0; i < arr.length; i++) {
        var arr2 = arr[i].split('=') // ["_ga", "GA1.1.1756734561.1561034020"]
        if (arr2[0].trim() === name) {
          return arr2[1]
        }
      }
    },
    clearCookie () {
      this.setCookie('', '', -1) // 清除cookie
    },
    // 验证手机号
    checkMobile (str) {
      const re = /^1\d{10}$/
      if (re.test(str)) {
        return true
      } else {
        return false
      }
    },
    // 发送验证码
    async sendCode () {
      const tel = this.loginUserForm.mobile
      if (this.checkMobile(tel)) {
        console.log(tel)
        // 发送验证码
        this.verifyQueryInfo.mobile = tel
        const { data: res } = await this.$http.get('verifyCode', {
          params: this.verifyQueryInfo
        })
        if (res.code !== 0) {
          return this.$message.error(res.message)
        }
        this.$message.success('获取验证码成功')
        let time = 60
        this.buttonText = '已发送'
        this.isDisabled = true
        if (this.flag) {
          this.flag = false
          const timer = setInterval(() => {
            time--
            this.buttonText = time + ' 秒'
            if (time === 0) {
              clearInterval(timer)
              this.buttonText = '重新获取'
              this.isDisabled = false
              this.flag = true
            }
          }, 1000)
        }
      } else {
        return this.$message.error('请输入正确的手机号')
      }
    },
    async sendCode2 () {
      const tel = this.resetPasswordForm.mobile
      if (this.checkMobile(tel)) {
        console.log(tel)
        // 发送验证码
        this.verifyQueryInfo.mobile = tel
        const { data: res } = await this.$http.get('verifyCode', {
          params: this.verifyQueryInfo
        })
        if (res.code !== 0) {
          return this.$message.error(res.message)
        }
        this.$message.success('获取验证码成功')
        let time = 60
        this.buttonText = '已发送'
        this.isDisabled = true
        if (this.flag) {
          this.flag = false
          const timer = setInterval(() => {
            time--
            this.buttonText = time + ' 秒'
            if (time === 0) {
              clearInterval(timer)
              this.buttonText = '重新获取'
              this.isDisabled = false
              this.flag = true
            }
          }, 1000)
        }
      } else {
        return this.$message.error('请输入正确的手机号')
      }
    },
    // 登录发送验证码
    sendCodeByLogin () {
      this.verifyQueryInfo.bizType = 31
      this.sendCode()
    },
    // 修改发送验证码
    sendCodeByReset () {
      this.verifyQueryInfo.bizType = 32
      this.sendCode2()
    },
    // 忘记密码
    forgetPassword () {
      this.isShow = false
      this.isShowForget = true
    },
    // 改变电话号码，同步
    changeMobile () {
      this.loginUserForm2.mobile = this.loginUserForm.mobile
      this.resetPasswordForm.mobile = this.loginUserForm.mobile
    },
    // 改变电话号码，同步
    changeMobile2 () {
      this.loginUserForm.mobile = this.loginUserForm2.mobile
      this.resetPasswordForm.mobile = this.loginUserForm2.mobile
    },
    // 忘记密码
    async resetPassword () {
      // 验证表单是否合理
      this.$refs.forgetFormRef.validate(async (valid) => {
        if (!valid) return
        // 验证通过，进行下一步操作
        const response = await this.$http.put(
          'resetPassword',
          this.resetPasswordForm
        )
        // console.log(response)
        if (response.data.code !== 0) {
          return this.$message.error(response.data.message)
        }
        this.$message.success('修改成功')
        this.isShowForget = false
        this.isShow = true
      })
    },
    // 返回
    goBack () {
      this.isShowForget = false
      this.isShow = true
    },
    // 密码只能数字+字母
    passwordInput (val) {
      // 账号的实时输入
      // console.log(val);
      const codeReg = new RegExp('[A-Za-z0-9]+') // 正则 英文+数字；
      const len = val.length
      let str = ''
      for (var i = 0; i < len; i++) {
        if (codeReg.test(val[i])) {
          str += val[i]
        }
      }
      this.loginUserForm2.password = str
    },
    passwordInput2 (val) {
      // 账号的实时输入
      // console.log(val);
      const codeReg = new RegExp('[A-Za-z0-9]+') // 正则 英文+数字；
      const len = val.length
      let str = ''
      for (var i = 0; i < len; i++) {
        if (codeReg.test(val[i])) {
          str += val[i]
        }
      }
      this.resetPasswordForm.password = str
    }
  }
}
</script>

<style lang="less">
.login {
  height: 100%;
  background-image: url('../../assets/img/login/1861.png');
  .login_container {
    position: absolute;
    left: 22%;
    top: 25%;
    width: 994px;
    height: 470px;
    background-color: #fff;
    display: flex;
    border-radius: 5px;
    .login_img {
      width: 490px;
      height: 470px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .login_box {
      .title {
        position: absolute;
        top: -60px;
        left: 550px;
        font-size: 28px;
        color: #fff;
      }
      .content {
        width: 450px;
        position: absolute;
        left: 540px;
        .mobile {
          width: 326px;
          img {
            width: 20px;
            margin-top: 7px;
          }
        }
        .varify {
          height: 40px;
          img {
            width: 20px;
            margin-top: 7px;
          }
        }
        .el-tabs {
          padding: 40px 60px;
          .el-tabs__nav-wrap {
            width: 240px;
          }
          .el-tabs__header {
            width: 200px;
            margin: 0 0 15px 40px;
          }
          .el-tabs__item {
            font-size: 20px;
          }
          .el-tab-pane {
            margin-top: 40px;
            width: 210px;
          }
        }
        .getVarify {
          position: relative;
          top: -40px;
          left: 215px;
        }
        .btn {
          width: 330px;
        }
        .reset {
          display: flex;
        }
        .btn2 {
          width: 160px;
        }
        .aboutPassword {
          display: flex;
          justify-content: space-between;
          width: 330px;
          .forget {
            font-size: 14px;
            color: #999999;
          }
          .forget:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
